import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import Biography from '../components/Biography'
import EventList from '../components/EventList'
import Container from 'react-bootstrap/Container'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

export const IndexPageTemplate = ({ image, headline, content, buttonText, buttonLink }) => (
  <Fragment>
    <Jumbotron
      fluid
      style={{
        background: `transparent url(${image}) no-repeat center center / cover`,
        height: '96vh',
        minHeight: '640px',
      }}
    >
      <Container className="jumbotron__title" style={ { color: "#f8f9fa" } }>
        <h2>{ headline }</h2>
        <p class="lead">{ content }</p>
        <Link to={ buttonLink }>
          <Button as="span" variant="outline-light">{ buttonText }</Button>
        </Link>
      </Container>
    </Jumbotron>
    <Container>
      <Row>
        <Col md={{ span: 3, order: 'last' }}>
          <EventList />
        </Col>
        <Col>
          <Biography />
        </Col>
      </Row>
    </Container>
  </Fragment>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  headline: PropTypes.string,
  content: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        headline={frontmatter.feature.headline}
        content={frontmatter.feature.content}
        buttonText={frontmatter.feature.buttonText}
        buttonLink={frontmatter.feature.buttonLink}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image
        feature {
          headline
          content
          buttonText
          buttonLink
        }
      }
    }
  }
`
