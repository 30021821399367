import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { HTMLContent } from './Content'

const Biography = () => {
  const { markdownRemark: about } = useStaticQuery(
    graphql`
      query biographyStaticQuery {
        markdownRemark(frontmatter: { path: { eq: "/about" } }) {
          frontmatter {
            title
          }
          html
        }
      }
    `
  )

  return (
    <div id="about">
      <h2 className="column-title">{ about.frontmatter.title }</h2>
      <HTMLContent content={ about.html } />
    </div>
  )
}

export default Biography
